import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "lib/apollo/apolloClient"
import React from "react"

type Props = {
  children: React.ReactNode
}

export const ClientApolloProvider: React.FC<Props> = ({ children }) => (
  <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
)
