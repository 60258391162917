import { TypePolicies } from "@apollo/client"
import { relayStylePagination } from "@apollo/client/utilities"

export const typePolicies: TypePolicies = {
  Customer: {
    fields: {
      sharedFiles: relayStylePagination(),
    },
  },
}
