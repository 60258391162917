import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client"
import { typePolicies } from "@~/common/lib/apollo/typePolicies"
import contextQueryHash from "@~/context/generated/client.graphql.gen.json"
import mainQueryHash from "generated/client.graphql.gen.json"
import typeInfo from "../../generated/type-info.gen"
import { debugFetchers, ENABLE_FETCH_DELAY } from "./helper"

const queryHash = { ...mainQueryHash, ...contextQueryHash }

const endPointLink = new HttpLink({
  uri: "/api/graphql",
  useGETForQueries: true,
  fetch: (uri, options: RequestInit) => {
    const fetcher = ENABLE_FETCH_DELAY ? debugFetchers["query"] : fetch

    if (options.method === "GET") {
      // クエリをハッシュ値に変換する
      const [path, queryString] = uri.toString().split("?")
      const params = new URLSearchParams(queryString)
      const { query, operationName, ...others } = Object.fromEntries(params)
      const hashedParams = new URLSearchParams({
        q: queryHash[operationName],
        operationName, // operationName はログへの記載とBFFでのキャッシュ制御用途に使用しています
        ...others,
      })
      return fetcher(`${path}?${hashedParams}`, options)
    }

    const { operationName } = JSON.parse(options.body as string)
    return fetcher(`${uri}?opname=${operationName}`, options)
  },
})

export const apolloClient = (() =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies,
      possibleTypes: typeInfo.possibleTypes,
    }),
    link: from([endPointLink]),
  }))()
