// Code generated by graphql-codegen; DO NOT EDIT.

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ApproachList": [
      "ContractAggregatedApproachList",
      "ManualApproachList"
    ],
    "ApproachListCustomer": [
      "ContractAggregatedApproachListCustomer",
      "ManualApproachListCustomer"
    ],
    "DbNode": [
      "Activity",
      "ActivityReport",
      "BeforeConsultation",
      "Calendar",
      "ConcierProfile",
      "Confirmation",
      "Consultation",
      "ContractAggregatedApproachList",
      "ContractAggregatedApproachListCustomer",
      "Conversion",
      "Customer",
      "CustomerAgreement",
      "CustomerAuth",
      "CustomerBondItem",
      "CustomerInvitation",
      "CustomerItem",
      "CustomerNote",
      "CustomerRelationship",
      "CustomerSbi",
      "CustomerSegment",
      "CustomerSensitiveNote",
      "CustomerSharedFile",
      "CustomerSurrogate",
      "CustomerToshinItem",
      "EducationExpense",
      "Event",
      "EventReport",
      "Exchange",
      "File",
      "FulfillmentRequest",
      "FulfillmentTransaction",
      "FutureAsset",
      "GrossSalesTarget",
      "Image",
      "Incident",
      "IntentionConfirmation",
      "Item",
      "ItemWeight",
      "Lifeplan",
      "ManualApproachList",
      "ManualApproachListCustomer",
      "Meeting",
      "MeetingLog",
      "MeetingReport",
      "NotificationEmailTransaction",
      "NotificationHistory",
      "NotificationLineTransaction",
      "NotificationTextTransaction",
      "PreContract",
      "Request",
      "Room",
      "SalesResult",
      "SalesTarget",
      "Seminar",
      "SeminarApplication",
      "SeminarTopic",
      "SeminarVideo",
      "SensitiveDataContactHistory",
      "Shift",
      "Shop",
      "Simulation",
      "SubGroup",
      "Surrogate",
      "ToshinItem",
      "User",
      "UserAppraisalPeriod",
      "Vendor"
    ],
    "Error": [
      "BasicError",
      "FieldError"
    ],
    "Node": [
      "Activity",
      "ActivityReport",
      "BeforeConsultation",
      "Calendar",
      "ConcierProfile",
      "Confirmation",
      "Consultation",
      "ContractAggregatedApproachList",
      "ContractAggregatedApproachListCustomer",
      "Conversion",
      "Customer",
      "CustomerAgreement",
      "CustomerAuth",
      "CustomerBondItem",
      "CustomerInvitation",
      "CustomerItem",
      "CustomerNote",
      "CustomerRelationship",
      "CustomerSbi",
      "CustomerSegment",
      "CustomerSensitiveNote",
      "CustomerSharedFile",
      "CustomerSurrogate",
      "CustomerToshinItem",
      "EducationExpense",
      "Event",
      "EventReport",
      "Exchange",
      "File",
      "FulfillmentRequest",
      "FulfillmentTransaction",
      "FutureAsset",
      "GrossSalesTarget",
      "Image",
      "Incident",
      "IntentionConfirmation",
      "Item",
      "ItemWeight",
      "Lifeplan",
      "ManualApproachList",
      "ManualApproachListCustomer",
      "Meeting",
      "MeetingLog",
      "MeetingReport",
      "NotificationEmailTemplate",
      "NotificationEmailTransaction",
      "NotificationHistory",
      "NotificationLineTemplate",
      "NotificationLineTransaction",
      "NotificationTextTemplate",
      "NotificationTextTransaction",
      "PreContract",
      "Request",
      "Room",
      "SalesResult",
      "SalesTarget",
      "Seminar",
      "SeminarApplication",
      "SeminarTopic",
      "SeminarVideo",
      "SensitiveDataContactHistory",
      "Shift",
      "Shop",
      "Simulation",
      "SubGroup",
      "Surrogate",
      "ToshinItem",
      "User",
      "UserAppraisalPeriod",
      "Vendor"
    ],
    "NotificationTemplate": [
      "NotificationEmailTemplate",
      "NotificationLineTemplate",
      "NotificationTextTemplate"
    ],
    "NotificationTransaction": [
      "NotificationEmailTransaction",
      "NotificationLineTransaction",
      "NotificationTextTransaction"
    ],
    "PreContractBaseItem": [
      "PreContractCompareItem",
      "PreContractItem"
    ]
  }
};
      export default result;
    