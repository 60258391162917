export const ENABLE_FETCH_DELAY = process.env.NODE_ENV === "development"

// ランダムに待ちを入れたfetch
const throttledFetch: (
  next: typeof fetch,
  kind: "query" | "mutation"
) => typeof fetch = (next, kind) => async (info, init) => {
  let wait = 0
  switch (kind) {
    case "mutation":
      // 0.8秒〜2.8秒の間でランダムにsleepを入れる
      wait = Math.random() * 2000 + 800
      break
    default:
      // 0.2秒〜1.2秒の間でランダムにsleepを入れる
      wait = Math.random() * 1000 + 200
      break
  }
  const r = await next(info, init)
    .then((r) => [r, null])
    .catch((e) => [null, e])
  await sleep(wait)
  if (r[1]) throw r[1]
  return r[0]
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const debugFetchers: Record<"query" | "mutation", typeof fetch> = {
  query: throttledFetch(fetch, "query"),
  mutation: throttledFetch(fetch, "mutation"),
}
